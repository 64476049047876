<template>
	<v-row align="center" justify="center" class="fill-height">
		<v-col cols="9" sm="3" md="3" lg="3">
			<v-card
				:disabled="true"
				height="250"
				:class="['mx-5', 'custom-card-wr']"
				align="center"
				justify="center"
			>
				<v-card-text class="pt-7">
					<v-img
						:src="require(`@/assets/images/historic-site.png`)"
						width="90"
					></v-img>
				</v-card-text>
				<v-card-text class="title font-weight-black black--text px-10">
					{{ $t("app.prepare_presentation.title") }}
				</v-card-text>
			</v-card>
		</v-col>

		<!-- Show finish dialog -->
		<StageFinishDialog
			v-if="dialog"
			v-model="dialog"
			current_step="app.prepare_presentation.title"
			:dialog="dialog"
			@close="dialog = false"
		></StageFinishDialog>
	</v-row>
</template>

<script>
import StageFinishDialog from "@/components/research/modules/_common/stages/FinishDialog";

export default {
	name: "PreparePresentationPage",

	data() {
		return {
			dialog: false,
		};
	},

	components: {
		StageFinishDialog,
	},

	created() {
		this.readyToListenEvents();
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("finish-prepare-presentation", (payload) => {
				this.dialog = true;
			});
		},
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off("finish-prepare-presentation");
	},
};
</script>
